<template>
  <v-card>
    <v-window v-model="window" touchless>

      <v-window-item :key="0">
        <v-card-text>
          <v-alert
            type="error"
            :value="true"
            prominent text
          >
            If you continue you will overwrite the current schedule and lose any matches that have already been played.
          </v-alert>
        </v-card-text>
      </v-window-item>

      <!-- Standings -->
      <v-window-item :key="1">
        <v-card-text>
          <v-row dense>
            <v-col cols="12">
              <v-alert type="info" prominent>
                The below standings will be used to fill the ladder.
              </v-alert>
            </v-col>
            <v-col cols="12">
              <group-display
                ref="standings"
                :group="groups[0]"
                :rounds="rounds"
                :teamIds="groupTeamsIds[0]"
                :division="division"
                :standings="standings"
                :i="0"
                :loading="loading"
                :ladder="true"
              ></group-display>
            </v-col>
          </v-row>
        </v-card-text>
      </v-window-item>

      <!-- Courts & Time Slots -->
      <v-window-item :key="2">
        <v-card-text>
          <v-row dense>
            <v-col cols="12">
              <v-select
                v-model="ladderOption"
                :items="ladderOptions.options"
                label="Ladders"
                color="color3"
                item-color="color3"
                item-text="description"
                item-value="id"
                return-object
             >
              </v-select>
            </v-col>
          </v-row>
          <v-expand-transition>
            <v-row dense v-if="ladders && ladders.length > 0">
              <v-col cols="12" v-for="(l, i) in ladders" :key="i">
                <v-card>
                  <v-card-title class="grey lighten-3">
                    Ladder {{ l.number }}
                  </v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" sm="6">
                        <v-select
                          v-model="l.courts"
                          :items="courts"
                          label="Courts"
                          item-text="name"
                          color="color3"
                          item-color="color3"
                          multiple chips small-chips deletable-chips clearable
                        ></v-select>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-select
                          v-model="l.slots"
                          :items="slots"
                          label="Time Slots"
                          item-text="time"
                          color="color3"
                          item-color="color3"
                          multiple chips small-chips deletable-chips clearable
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-expand-transition>
        </v-card-text>
      </v-window-item>

      <!-- Match Settings -->
      <v-window-item :key="3">
        <v-card-text>
          <div class="title">Match Settings</div>
          <v-divider></v-divider>
          <v-row>
            <v-col cols="12" md="6" v-for="(setting) in poolSettings" :key="setting.poolsOf">
              <v-card class="elevation-1">
                <v-toolbar dense color="color2 color2Text--text" flat>
                  <v-toolbar-title>Ladders of {{setting.poolsOf}}</v-toolbar-title>
                </v-toolbar>
                <v-card-text v-if="getTemplates(setting.poolsOf).length" class="py-0">
                  <v-select
                    :items="getTemplates(setting.poolsOf)"
                    item-text="name"
                    item-value="id"
                    v-model="setting.organizationTemplateId"
                    label="Custom Template"
                    color="color3"
                    item-color="color3"
                  ></v-select>
                </v-card-text>
                <match-settings-editor
                  :setting="setting"
                  :edit="true"
                  :notime="true"
                ></match-settings-editor>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-window-item>

      <!-- complete -->
      <v-window-item :key="4">
        <v-card-text>
          <div class="title">Create Schedule</div>
          <div class="text-center">
            <v-btn
              color="color3 color3Text--text"
              class="ma-5"
              :loading="loading"
              @click.stop="goFill"
            >
              Save & Create Schedule
            </v-btn>
          </div>
        </v-card-text>
      </v-window-item>

    </v-window>
    <v-divider></v-divider>
    <v-card-actions>
      <v-fab-transition>
        <v-btn
          color="color3 color3Text--text"
          fab
          @click.stop="prev"
          :disabled="loading"
        >
          <v-icon>fas fa-caret-left</v-icon>
        </v-btn>
      </v-fab-transition>
      <v-spacer></v-spacer>
      <v-fab-transition>
        <v-btn
          color="success white--text"
          fab
          :key="valid[window]"
          @click.stop="next"
          :disabled="!valid[window] || loading"
        >
          <v-icon>fas fa-caret-right</v-icon>
        </v-btn>
      </v-fab-transition>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import MatchSettingsEditor from '@/components/Tournament/RoundSettings/MatchSettings.vue'
import MatchSettings from '@/classes/MatchSettings'
// import flatten from '@/helpers/ArrayFlatten'
import PoolOptions from '@/classes/PoolOptions'
import uniq from 'lodash.uniq'
import flatten from '@/helpers/ArrayFlatten'
import GroupDisplay from '@/components/Standings/GroupDisplay'

export default {
  props: ['round', 'division'],
  data () {
    return {
      gotit: true,
      window: 1,
      courts: [],
      selectedCourts: [],
      slots: [],
      selectedSlots: [],
      matchSettings: [],
      loading: false,
      thisRoundOnly: false,
      weeks: [],
      autoGen: true,
      ladderOption: null,
      templates: [],
      ladders: [],
      seededTeams: []
    }
  },
  computed: {
    ...mapGetters(['tournament', 'host']),
    standings () {
      return this.division && this.division.standings[0]
    },
    groups () {
      return this.standings && this.standings.groups ? this.standings.groups : []
    },
    rounds () {
      return this.standings && this.tournament && this.tournament.rounds.filter(f => this.standings.roundIds.includes(f.id) && f.number < this.round.number)
    },
    availableTeams () {
      return this.division.isLeague ? uniq(flatten(this.division.activeTeams.map(m => m.id))) : uniq(flatten(this.rounds.map(m => m.allTeams.map(t => t.teamId))))
    },
    groupTeamsIds () {
      const result = []
      this.standings.groups.forEach(g => {
        const usedTeams = flatten(result)
        const teamIds = g.type === 'all' ? this.availableTeams : g.type === 'spec' ? g.teamIds : this.availableTeams.filter(f => !usedTeams.includes(f))
        result.push(teamIds)
      })
      return uniq(result)
    },
    ladderOptions () {
      return new PoolOptions(this.division.activeTeams.length, false, this.templates, 'Ladder')
    },
    settings () {
      return this.tournament.leagueSettings
    },
    valid () {
      return [
        this.gotit,
        true,
        this.ladders.length > 0 && this.ladders.findIndex(f => !f.valid) === -1,
        true,
        false
      ]
    },
    poolsOf () {
      return this.ladderOption ? [...new Set(this.ladderOption.pools)] : []
    },
    poolSettings () {
      return this.matchSettings.filter(f => this.poolsOf.includes(f.poolsOf))
    },
    dto () {
      return {
        tournamentId: this.tournament.id,
        divisionId: this.division.id,
        roundId: this.round.id,
        matchSettings: this.poolSettings.map(m => m.dto),
        ladders: this.ladders,
        seededTeams: this.seededTeams
      }
    },
    // simulator
    poolList () {
      const list = []
      this.weeks.forEach(w => list.push(...w.poolList))
      return list
    }
  },
  methods: {
    next () {
      if (this.window === 1) {
        if (this.$refs.standings) {
          this.seededTeams = this.$refs.standings.rankedTeams.map(t => {
            return {
              tournamentTeamId: t.teamId,
              seed: t.rank,
              map: `Round ${this.round.number - 1} Finish: ${t.rank}`
            }
          })
        }
        console.log(this.seededTeams)
      }
      this.window += 1
    },
    prev () {
      if (this.window <= 1) {
        this.init()
        this.$emit('cancel')
        return
      }
      this.window -= 1
    },
    onOptionsSelected () {
      const n = this.ladderOption.pools.length

      while (this.ladders.length > n) {
        this.ladders.pop()
      }

      while (this.ladders.length < n) {
        this.ladders.push({
          number: this.ladders.length + 1,
          courts: [],
          slots: [],
          get valid () {
            return this.courts.length > 0 && this.slots.length > 0
          }
        })
      }
    },
    ensureSettings () {
      const done = this.matchSettings.map(m => m.poolsOf)
      this.round && this.round.flight && this.round.flight.settings.forEach(s => {
        if (s.poolsOf) {
          const x = done.includes(s.poolsOf)
          if (!x) {
            const dto = JSON.parse(JSON.stringify(s.dto))
            dto.id = 0
            this.matchSettings.push(new MatchSettings(dto, false, false))
          }
          done.push(s.poolsOf)
        }
      })
      this.poolsOf.forEach(f => {
        const x = done.includes(f)
        if (!x) {
          this.matchSettings.push(new MatchSettings({
            poolsOf: f,
            matchTypeId: 1
          }, true, true))
        }
      })
    },
    init () {
      this.seededTeams = []
      const l = this.ladderOptions.options
      if (l.length === 1) this.ladderOption = l[0]
      if (this.round.hasMatches) this.window = 0
      this.courts = this.settings.courtNames.map((m, i) => {
        return {
          name: m,
          id: `court-${i}`
        }
      })
      this.slots = this.settings.slots.map((m, i) => {
        return {
          time: m,
          id: `slot-${i}`
        }
      })
      this.ensureSettings()
    },
    goFill () {
      console.log(this.dto)
      // this.$store.commit('SET_ROUND_JPROP', this.dto)
      // this.loading = true
      // this.tournament.saveJProps()
      //   .then(r => this.$emit('complete'))
      //   .finally(() => {
      //     this.loading = false
      //   })
      this.loading = true
      this.$VBL.tournament.league.fillLadders(this.dto)
        .then(r => this.$emit('complete'))
        .catch(e => console.log('error'))
        .finally(() => {
          this.loading = false
        })
    },
    getTemplates (n) {
      const a = (this.templates || []).filter(f => f.type === 'Pool' && f.template.poolsOf === n)
      if (a.length) a.unshift({ name: 'None', id: null })
      return a
    },
    loadTemplates () {
      this.loading = true
      this.$VBL.organization.templates.get(this.host, 'pool')
        .then(r => {
          this.templates = r.data
        })
        .catch(e => console.log(e.response))
        .finally(() => {
          this.loading = false
        })
    }
  },
  watch: {
    poolsOf: 'ensureSettings',
    ladderOption: 'onOptionsSelected'
  },
  mounted () {
    this.loadTemplates()
    this.init()
  },
  components: {
    MatchSettingsEditor,
    GroupDisplay
  }
}
</script>
