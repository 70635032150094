import range from 'lodash.range'
import sum from 'lodash.sum'
import uniq from 'lodash.uniq'

export default class PoolOptions {
  constructor (teams, kob, custom, word) {
    this.options = []
    this.kob = kob
    this.custom = custom || []
    this.word = word || 'pool'

    for (let i = 1; i <= Math.floor(teams / 2); i++) {
      const pools = []
      const divisors = range(1, i + 1)
      divisors.forEach(x => {
        const n = Math.floor(teams / i)
        pools.push(n)
        if (x === i) {
          const y = teams - sum(pools)
          for (let z = 0; z < y; z++) {
            pools[z] += 1
          }
        }
      })

      pools.sort()

      const description = uniq(pools).map(n => {
        const count = pools.filter(f => f === n).length
        const p = count === 1 ? `${count} ${this.word}` : `${count} ${this.word}s`
        return `${p} of ${n} teams`
      })

      const a = pools.length === 1 ? `${pools.length} ${this.word}` : `${pools.length} ${this.word}s`

      this.options.push({
        id: pools.length,
        pools: pools.sort(),
        description: `${a} (${description.join(', ')})`,
        min: pools[0],
        max: pools[pools.length - 1]
      })
    }

    if (this.kob) {
      this.options = this.options.filter(o => o.min > 3)
    } else {
      this.options = this.options.filter(o => (o.min > 2 && o.max < 8) || this.custom.includes(o.max))
    }
  }

  get suggestedOption () {
    if (this.options.length === 0) return null
    const opt0 = this.options.find(o => o.min >= 4 && o.max <= 4)
    const opt1 = this.options.find(o => o.min >= 3 && o.max <= 4)
    const opt2 = this.options.find(o => o.min >= 3 && o.max <= 5)
    const r = this.options.reverse()
    const opt3 = r.find(o => o.min >= 4)
    const opt4 = r.find(o => o.max <= 5)
    return opt0 || opt1 || opt2 || opt3 || opt4
  }
}
